#about {
  .title {
    font-size: 34px;
    margin-bottom: 40px;
    margin-top: 0;
  }
  .title2 {
    margin-top: 0;
  }
  .bg-about {
    background-image: url("../images/banner-about.jpg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: cover;
  }
  .bg-reference {
     background-image: url("../images/banner-reference.jpg");
     background-repeat: no-repeat;
     background-position: left center;
     background-size: cover;
   }

  .bottom-reference {
    padding-bottom: 150px;
    background-image: url("../images/bg-reference.jpg");
    background-repeat: no-repeat;
    background-position: left bottom;
    h2{
      font-size: 28px;
    }
    .btn{
      padding: 15px 25px;
    }
}
  .person-reference{
    text-align: center;
    margin-bottom: 30px;

    .person-name {
     font-size: 18px;
      font-weight: 500;
      color: $dark-gray-color;
      display: block;
      margin-bottom: 0!important;
    }
    .person-country {
      position: relative;
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 15px;
      color: $dark-gray-color;
      &:after {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        bottom: -10px;
        content: "";
        height: 5px;
        width: 25px;
        background-color: $primary-color;
      }
    }
  }
  .header-about {
    padding-bottom: 30px;
    padding-top: 30px;
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.95) 30%, rgba(255, 255, 255, 0.95) 31%, rgba(255, 255, 255, 0) 75%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.95) 30%, rgba(255, 255, 255, 0.95) 31%, rgba(255, 255, 255, 0) 75%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(255, 255, 255, 0.95) 30%, rgba(255, 255, 255, 0.95) 31%, rgba(255, 255, 255, 0) 75%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e6ffffff', endColorstr='#00ffffff', GradientType=1); /* IE6-9 */
    .content-header {
      h3 {
        font-size: 24px;
        margin-bottom: 40px;
      }
      p {
        margin-bottom: 30px;
      }
    }
  }
  .content-seal {
    div {
      width: 100%;
      padding: 20px;
    }
    .seal-top {
      background-color: $header-color;
    }

    .seal-bottom {
      background-color: #fcefef;
    }
  }

  .about-team {
    padding-top: 40px;
    background-color: #f2f1ef !important;
  }

  .team-card {
    padding: 15px;
    background-color: $header-color;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.53);
    -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.53);
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.53);
    cursor: pointer;
    @include transition();
    &:hover {
      @include transition();
      .list-field {
        .resume {
          display: block;
        }
      }
    }
    .dashed-line {
      border-left: dashed 1px #cccccc;
      padding-right: 0;
    }
    .list-field {
      position: relative;
      padding-left: 15px;
      padding-right: 15px;

      .resume {
        display: none;
        position: absolute;
        padding: 20px;
        z-index: 2;
        right: 0;
        top: 0;
        width: 50%;
        height: 112%;
        background-color: $primary-color;
        color: $header-color;
        @include transition();
        .arrow-resume {
          @include transition();
          content: "";
          z-index: 2;
          background-color: $primary-color;
          width: 15px;
          height: 15px;
          position: absolute;
          top: 35%;
          left: -8px;
          -ms-transform: rotate(-5deg); /* IE 9 */
          -webkit-transform: rotate(-45deg); /* Safari */
          transform: rotate(-45deg);
        }
        .close{
          display: none;
          position: relative;
          width: 100%;
          text-align: center;
          opacity: 1;
          margin-bottom: 10px;
          i{
            color:#fff;
            font-size: 20px;
          }
          @media (max-width: 991px) {
            display: block;
          }
        }

      }
    }

    ul {
      width: 100%;
      li {
        display: inline;
        margin: 0;
        padding: 0;
        float: left;
        width: 50%;
        div {
          width: 100%;
          padding: 10px;
          border: solid 1px #cccccc;
          font-size: 16px;
          font-weight: 500;
          color: #000000;
          span {
            display: block;
            font-weight: 300;
          }
        }

        &:first-child {
          float: none;
          div {
            width: 100%;
            span {

              font-size: 28px;
              color: $primary-color;
              font-weight: 900;
            }
          }
        }
      }
    }
    .barcode-div {

      svg {
        height: 300px;
        width: auto;
      }
    }

    .ticket-board {
      font-size: 40px;
      color: #e8e8e8;
    }
    h4 {
      margin-bottom: 20px;
      position: relative;
      &:after {
        position: absolute;
        left: 0;
        bottom: -10px;
        content: "";
        height: 5px;
        width: 25px;
        background-color: #acd226;
      }
    }
  }
 .logos{
   ul{
     text-align: center;
     margin-top: 20px;
     margin-bottom: 20px;
     li{
       display: inline;
       img{
         margin: 20px 10px;
       }

     }
   }
 }
}


.team-card ul li {
  &:nth-child(4), &:nth-child(5) {
    div {
      min-height: 90px;
    }
  }
}

.tours-block--content {
  p {
    font-size: 14px;

    strong {
      color: #808080;
    }
  }
}

.address-title {
  font-size: 18px;
  margin: 10px 0;
}
