//cores
$primary-color:#acd226;
$secundary-color: #ffaf15;
$background-color: #fff;
$light-gray-color: #efecec;
$mid-gray-color: #656464;
$dark-gray-color: #494848;
$black-color: #000;
$snow-color: #f9faf9;
$header-color: #fff;
$sidebar-color: #3a3e64;

$active-pagination-color: #00B4D3;
$menu-mob-background-color: #5DBBD1;
$clean-safe:#dadada;