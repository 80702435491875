#homepage {
  margin-top: 20px;
.circuit{

}
  .blog-list-post {

    .list-title {
      padding-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 0;
      background-color: #efecec;
      margin-bottom: 20px;
      h2 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
        text-align: left;
      }
      .list-btn {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

    } .btn {
        padding: 6px 60px;
        margin-bottom: 0;
        font-size: 16px;
    background-color: $secundary-color;
    &:hover{
      background-color: $primary-color;
    }
      }
    .btn2 {
      background-color: $primary-color;
      &:hover{
        background-color: $secundary-color;

      }
    }


    & > a {
      color: #333;
      text-decoration: none;

      .blog-list-image--wrapper {
        overflow: hidden;

        img {
          @include transition;
        }
      }

      &:hover {
        .blog-list-image--wrapper img {
          -webkit-transform: scale(1.2);
          -moz-transform:    scale(1.2);
          -ms-transform:     scale(1.2);
          -o-transform:      scale(1.2);
          transform:         scale(1.2);
        }

        .btn {
          background-color: #acd226;
        }
      }
    }
  }
  .go_away{

  }
  .cta-hp{
    padding: 15px;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: $dark-gray-color;
    p{
      display: block;
      width: 100%;
      margin:20px 0 10px 0;
      text-align: center;
    }
    svg{
      .st0{
        fill: none;
      }
      .verde{
        fill: $primary-color;
      }
      width: 100%;
    }
  }
  .edges{
    border-bottom: solid 1px $light-gray-color;
  }
  .top-sells{
     padding-right: 0;
     padding-left: 0;


    .top-big{
      padding-right: 0;
      padding-left: 0;
      position: relative;
      @include transition();
      &:hover{
        @include transition();
        .top-tour-time{
          display: block
        }
        .tour-cta{
          display: block;
        }
      }

      img{
        min-width: 100%;
        height: auto;
      }
    }
    .top-small{
      padding-right: 0;
      padding-left: 0;
    }
    .small-tour{
      padding-right: 0;
      padding-left: 0;
      position: relative;
      overflow-y: hidden;
      min-height: 50%;
      display: flex;
      @include transition();
      &:hover{
        @include transition();
        .top-tour-time{
          display: block
        }
        .tour-cta{
          display: block;
        }
      }
      img{
        min-width: 100%;
        height: auto;
      }
    }



    .top-tour-price{
      position: absolute;
      z-index: 10;
      top: 10px;
      right: 10px;
      padding: 3px 10px;
      font-size: 17px;
      font-weight:900;
      color: $header-color;
      background-color: $primary-color;
      span{
        font-size: 12px;
        font-weight:400;
      }
    }
    .top-tour-title{
      color: $header-color;
      background-color: rgba(0,0,0, 0.8);
      padding:6px;
      text-align: center;
      width: 100%;
      height: auto;
      h5{
        font-size: 17px;
        font-weight:400;
        margin-bottom: 2px;
        margin-top:2px;
      }
      .top-tour-time{
        display: none;
        font-size: 12px;
      }
      .tour-cta{
        font-size: 14px;
        text-align: center;
        display: none;
        padding: 0;
      }
    }
    .top-tour-title2{
      color: $header-color;
      background-color: rgba(0,0,0, 0.8);
      padding:6px;
      text-align: center;
      width: 100%;


      h5{
        font-size: 17px;
        font-weight:400;
        margin-bottom: 2px;
        margin-top:2px;
      }
      .top-tour-time{
        display: block;
        font-size: 12px;
      }
      .tour-cta{
        font-size: 14px;
        text-align: center;
        display: block;
        padding: 0;
      }
    }
    .wrap-title{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 10px;
      display: table-cell;
      @include transition();

    }


  }

}

.banner-home {
  position: relative;
  overflow: hidden;
  img {
    min-width: 100%;
    height: 400px;
    object-fit: cover;
    @media (min-width: 992px) {
      height: auto;
    }
  }
  .banner-mobile {

  height: auto;

  }
  .slick-dots .slick-active button {
    position: relative;
    &:after{
      font-family: FontAwesome;
      content: "\f041"!important;
      color: $primary-color;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      font-size: 28px;
      position: absolute;
      top:-10px;
      left:-4px;
    }
    &::before{
      content: attr(title);
      font-family: Roboto;
      text-align: left;
      color: #FFF!important;
      font-size: 16px;
      position: absolute;
      top:-23px;
      left:15px;
      opacity: 1!important;
    }
  }
  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }

  .slick-dots {
    position: absolute;
    z-index: 16;
    bottom: 5px;
    right: 100px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: right;

    li {
      position: relative;
      display: inline-block;
      width: 12px;
      height: 12px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
      button {
        position: relative;
        display: inline-block;
        width: 12px;
        height: 12px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        background-color: transparent;
        border: solid 2px white;
      }
    }
  }

  .form-search {
    position: absolute;
    z-index: 98;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -40%);
    width: 100%;
    h2 {
      font-size: 48px;
      color: #FFF;
      margin-bottom: 30px;
      text-align: center;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
      span {
        display: inline;
        font-weight: 900;
      }
    }
    .bg-search {

      width: 100%;
      background-color: rgba(0, 0, 0, 0.45);
    }
  }
  .bg-svg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 12;
    background: rgba(0, 0, 0, 0.5);
    background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50%);
    background: -webkit-gradient(left bottom, left top, color-stop(0%, rgba(0, 0, 0, 0.5)), color-stop(50%, rgba(0, 0, 0, 0)));
    background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50%);
    background: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50%);
    background: -ms-linear-gradient(bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50%);
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=0);
  }

  .banner-home--item-slider{
    position: relative;
  }

}


.banner-home-content{
  position: absolute;
  width: 100%;
  bottom: 30px;
  left: 0;
  z-index: 1;
  @media (min-width: 992px){
    bottom:50px;
  }

  .banner-home-title{
    @include flexbox;
    @include align-items(center);
    width: 100%;
    max-width: 350px;
    height: auto;
    font-size: 30px;
    font-weight: bold;
    color: #acd226;
    background-color: rgba(229,229,229,.8);
    padding: 2.5px 15px 2.5px 10px;
    margin-bottom: 8px;
    line-height: 1;
    text-transform: uppercase;
    overflow: hidden;
    @media (min-width: 768px) {
      font-size: 45px;
    }
    @media (min-width: 992px) {
      max-width: 550px;
      height: 50px;
    }
  }


  .banner-home-description{
    @include flexbox;
    @include align-items(center);
    width: 100%;
    max-width: 300px;
    height: auto;
    font-size: 16px;
    color: #464646;
    background-color: rgba(229,229,229,.8);
    padding: 2.5px 15px 2.5px 10px;
    margin-bottom: 8px;
    line-height: 1;
    overflow: hidden;
    @media (min-width: 768px) {
      font-size: 20px;
    }
    @media (min-width: 992px) {
      max-width: 500px;
      height: 45px;
    }
  }

}





.form-banner {
  padding: 10px 20px;
  .btn {
    height: 20%;
    font-size: 20px;
    font-weight: 500;
    background-color: $primary-color;
    text-transform: uppercase;
    padding: 30px 20px !important;
    margin-bottom: 10px;
    margin-top: 10px;
    width: -moz-calc(8% - 3px);
    /* WebKit */
    width: -webkit-calc(8% - 3px);
    /* Opera */
    width: -o-calc(8% - 3px);
    /* Standard */
    width: calc(8% - 3px);
    margin-right: 0;


    @include transition();
    &:hover, &:active:hover {
      @include transition();
      background-color: $secundary-color;
    }
  }
  .form-fields {
    position: relative;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 10px;
    background-color: #fff;
    height: auto;
    margin-right: 1px;
  }

  .title-input {
    font-size: 24px !important;
    color: black;
    margin-bottom: 0 !important;
  }

  .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 0 0;
    font-size: 15px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .0);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  }

  .form-group {
    margin-bottom: 0;
  }

  .input-group-addon {
    padding: 0 0;
    font-size: 24px;
    position: relative;
    top: -35px;
    font-weight: 400;
    line-height: 0;
    color: $primary-color;
    text-align: right;
    background-color: #fff;
    border: none;
    border-radius: 0;
  }

}

.heading-style {
  font-size: 44px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 10px;
}

.heading-sub--style {
  position: relative;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 40px;

  &:after {
    position: inherit !important;
  }
}

.top-sales--link {
  float: left;
  width: 100%;
}
