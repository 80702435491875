@import "../css/bootstrap.min.css";
@import "../css/plugins/slick.min.css";
@import "../css/plugins/slick-theme.min.css";
@import "../css/animate.min.css";
@import "../css/font-awesome.min.css";
@import "../css/plugins/bootstrap-datepicker3.css";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800,800italic|Kalam:700,400');

@import "base/fonts";
@import "base/variaveis";

@import "helpers/mixins";

@import "components/buttons";
@import "components/navigation";
@import "components/plugins";

@import "layout/pages";
@import "layout/header";
@import "layout/footer";


@import "pages/homepage";
@import "pages/tour";
@import "pages/help";
@import "pages/about";
@import "pages/blog";
@import "pages/galeria";
@import "pages/sustainable";
@import "pages/budget";
@import "pages/contactos";

@import "mediaqueries/mediaqueries";