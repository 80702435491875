.menu-wrapper {
    text-align: right;
    font-family: 'Roboto';
    font-weight: 500;
    position: relative;
    z-index: 99;
    .sub-menu{
        position: absolute;
        top: -15px;
        right: 15px;
        padding-top: 0;
        margin-top: 0;
        ul {
            position: relative !important;
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                margin: 0 !important;
                background-color: #ffffff;
                display: inline-block;
                padding: 4px 8px;
                text-align: center;
                border-left: solid 1px #a8a5a5;
                border-bottom: solid 1px #a8a5a5;
                margin-right: -4px!important;
                a{
                    font-size: 14px;
                    color: #a8a5a5;
                    text-transform: capitalize;
                    font-weight: 300;
                }
                &:last-child{
                    background-color: $secundary-color;
                    border-bottom: solid 1px $secundary-color;
                    border-left: solid 1px $secundary-color;
                    padding: 4px 8px;
                    @include transition();

                    &:hover{
                        @include transition();
                        background-color: $primary-color;
                        border-bottom: solid 1px $primary-color;
                        border-left: solid 1px $primary-color;
                    }

                    a{
                        color: #ffffff;
                        font-weight: 500;
                    }
                }

            }
        }
    }
    ul{
        /*position: absolute;
        z-index: 9;
        list-style: none;
        bottom: 0;
        right: 0;
        margin:0;
        padding-right:15px;*/
        font-size: 17px;
        display: flex !important;
        justify-content: space-between;

        li{

            display: inline-block;
            padding:0 5px;
            text-align: center;
            a{

                color: $black-color;
                text-transform: uppercase;
                &:hover, &:focus{
                    color: $primary-color;
                    text-decoration: none;
                    @include transition;
                }
            }
            &:last-child {
            padding: 0;

            }
        }
    }
}

.phone_wrapper {
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    height: 100%;
    @include flexbox();
    @include justify-content(flex-end);
    @include align-items(center);
    .first-row {
        float: right;
        .image-wrapper {
            display: inline-block;
            vertical-align: top;

            img {
                width: 100%;
            }
        }
        .phone {
            display: inline-block;
            vertical-align: middle;
            font-size:20px;
            margin: 0;
            a {
                color: $primary-color;
                letter-spacing:25;
                font-weight: 700;
                line-height: 100%;
            }
            .email {
                display: block;
                margin-top: -5px;
                text-align: left;
                a {
                    font-weight: 300;
                    text-transform: lowercase;
                    font-size: 15px;
                    color: #b8b8b8;

                }

            }

        }
    }


}

.select-lang-wrapper {
    .select-lang {
        cursor: pointer;
        width: 50px;
        float: right;
        border-radius: 0;
        font-size: 12px;
        padding: 5px;
        margin: 10px 0px;
    }
}
.base{
    position: relative;
}
.hamburger {
    text-align: right;
    cursor: pointer;
    position: absolute;
    @include inline-flex;
    @include align-items(center);
    right:15px;
    top:15px;
    color: $primary-color;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;

    z-index: 100;
    i {
        margin-left: 5px;
        font-size: 20px;
        color: $primary-color;
        &:before{
         content: "\f0c9"
        }
    }
    &.open{
        i {
            &:before{
                content: "\f00d"
            }
        }
    }
}

.close-icon {
    cursor: pointer;
    padding: 20px !important;
}
.menu-itens{
    position: absolute;
    z-index: 17;
    top:0;
    left: 0;
    height: 100vh;

}
