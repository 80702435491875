footer {
  background-color: $dark-gray-color;
  color: $header-color;
  padding-bottom: 20px;
}

.container-custom {
  max-width: 1170px;
  width: 100%;
  margin: auto;
}

.newsletter {
  padding-top: 15px;
  padding-bottom: 30px;
  border-bottom: solid 1px $mid-gray-color;
  position: relative;

  .news-form {
    vertical-align: top;
    width: -moz-calc(40% - 4px);
    /* WebKit */
    width: -webkit-calc(4% - 4px);
    /* Opera */
    width: -o-calc(40% - 4px);
    /* Standard */
    width: calc(40% - 4px);
    margin-right: -2px;
    .form-control {
      width: 100%;

    }
    &:last-child {
      width: 20%;
      margin-right: 0;
      margin-left: -1px;
    }
  }
  h4 {
    position: relative;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  /* icones socialnetwork list */
  ul.social-network {
    list-style: none;
    display: inline;
    margin-left: 0 !important;
    padding: 0;
    text-align: right;
    width: 100%;
  }
  ul.social-network li {
    display: inline;
    margin: 0 5px;
  }

  /* icones socialnetwork */

  .social-network a {
    background-color: $dark-gray-color;
    border: solid 2px $primary-color;
    @include transition();
  }
  .social-network a:hover {
    background-color: $primary-color;
    @include transition();
  }

  .social-circle li a {
    display: inline-block;
    position: relative;
    margin: 0 auto 0 auto;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    width: 40px;
    height: 40px;
    font-size: 20px;
    border: solid 2px $header-color;
  }

  .social-circle li i {
    margin: 0;
    line-height: 40px;
    text-align: center;
  }

  .social-circle li a:hover i, .triggeredHover {
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -ms--transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: all 2s;
    -moz-transition: all 2s;
    -o-transition: all 2s;
    -ms-transition: all 2s;
    transition: all 2s;
  }
  .social-circle i {
    color: #fff;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
  }

  a {
    background-color: $dark-gray-color;
  }

  input {
    border: none !important;
    border-radius: 0 !important;
    height: 42px !important;
  }
  button {
    background-color: $secundary-color;
    color: $header-color;
    font-size: 18px;
    font-weight: 500;
    min-height: 42px;
    margin: 0;
    @include transition;

    &:hover {
      background: $primary-color;
      color: $header-color;
    }
  }
}

.menu-footer {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: solid 1px $mid-gray-color;

  .pme {
    position: relative;
    img {
      align: right;
      margin-top: 0;
      position: absolute;
      right: 15px;
      height: auto;
      width: 78px;
    }
  }
  h5 {
    position: relative;
    font-size: 16px;
    font-weight: 900;
    margin-bottom: 30px;
    &:after {
      position: absolute;
      left: 0;
      bottom: -10px;
      content: "";
      height: 5px;
      width: 25px;
      background-color: $primary-color;

    }
  }
  li {
    margin-bottom: 5px;
    a {
      color: $header-color;
      @include transition();
      &:hover {
        @include transition();
        color: $primary-color;
      }
    }
  }
}

.copy-footer {
  padding-top: 20px;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  @include flexbox;
  @include flex-direction(column);
}

.wrapper {
  @include flex(1);
}
