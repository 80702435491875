
header{
    font-size: 16px;
}
.shadow-header {
    -webkit-box-shadow: 0px 5px 2px 2px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 5px 2px 2px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.35);
    background-color: $header-color;
    position:relative;
    z-index: 999;
}

.logo-circles {
    img {
        width: auto;
        height: 100%;
        max-height: 50px;
        position: relative;
        margin-right: 5px;
    }
}

.logo {
    display: inline-block;
    vertical-align: middle;
    flex : initial!important;
    z-index: 125;
    position: relative;
    width: 100%;

    a {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        padding: 6px 0px;
        width: 100%;
    }
    span {
        font-style: italic;
        font-size:24px;
        font-family: 'Arial Rounded MT Bold';
        font-weight: 700;
        line-height: 100%;
    }
    span:first-of-type {
        color: $primary-color;
        letter-spacing:0;
    }
    span:last-of-type {
        color: $black-color;
        letter-spacing:0;
    }

    p {
        line-height: 100%;
        color: $black-color;
        font-family: 'Open Sans', sans-serif;


        &.logo-slogan {
            color: #9f9f9f !important;
            width: 100%;
            font-size: 13px;
        }
    }
}

.text-ecotours {
    width: 100%;
}

.telephone-mobile{
    background-color: $primary-color;
    padding: 5px;
    vertical-align: middle;
    text-align: left;
    height: 36px;
    position: relative;
display: table;
    .first-row {
        display: table-cell;
        width: 60%;
        .image-wrapper {
            display: inline-block;
            vertical-align: top;
            img {
                width:80%;
            }
        }
        .phone {
            display: inline-block;
            vertical-align: middle;
            font-size:15px;
            margin: 0;
            a {
                color: $header-color;
                letter-spacing:25;
                font-weight: 900;
                line-height: 100%;
            }

        }
    }
    .second-row{

        display: table-cell;
        width: 40%;
        .email {
            display: block;
            margin-top: -5px;
            text-align: left;
            a {
                font-weight: 300;
                text-transform: lowercase;
                font-size: 12px;
                color: $header-color;

            }
        }
    }
}

.new-header--wrapper {
    float: left;
    height: 70px;
    @include flexbox;
    @include justify-content(flex-end);

    .sub-menu {
        @include flexbox;
        @include align-items(center);
        position: initial;
        top: 0;
        right: 0;
        padding: 0;
        margin: 0 30px 0 0;
    }
}

.menu-wrapper .sub-menu ul li {

    &:nth-child(1), &:nth-child(2), &:nth-child(3) {
        border: none;
    }

/*    &:last-child {
        border-top: 1px solid $primary-color;
    }*/
}

.header-bg--wrapper .menu-wrapper ul li a {
    padding: 15px 0;
    float: left;
    width: 100%;
    border-bottom: 5px solid #efecec;
}

.header-bg--wrapper .menu-wrapper ul li {
    @include flex(1);
    padding: 0 !important;

    &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
        border-left: 1px solid #fff;
    }
}

.header-bg--wrapper {
    background: #efecec !important;
}

.down-menu li a:hover, .down-menu li a:focus {
    color: $primary-color !important;
    text-decoration: none !important;
    @include transition();
    border-bottom: 5px solid $primary-color !important;
}

.header-active--full {
    border-bottom: 5px solid $primary-color !important;
    color: $primary-color !important;
}

.text-logo-new {
    margin: 62px 0 0 -159px;
}

.active-top--menu {
    a {
        color: $primary-color !important;
    }
}