@mixin transition(){
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

@mixin transition-detailed($property, $duration, $timing){
    -webkit-transition: $property $duration $timing;
    -moz-transition: $property $duration $timing;
    -o-transition: $property $duration $timing;
    transition: $property $duration $timing;
}

@mixin prefix($property, $value, $prefixes: ()) {
    @each $prefix in $prefixes {
        #{'-' + $prefix + '-' + $property}: $value;
    }

    // Output standard non-prefixed declaration
    #{$property}: $value;
}

//Como usar ? -> @include prefix(transform, rotate(45deg), webkit ms);
// Exporta : -webkit-transform: rotate(45deg);
//         -ms-transform: rotate(45deg);
//         transform: rotate(45deg);

/*center stuff*/
@mixin push--auto {
    margin: {
        left: auto;
        right: auto;
    }
}

/*include in afters e befores*/
@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}

/*reponsive proportion*/
@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

/*placeholder*/

@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}

/*how to use Placeholder Mixin*/
/*input,
textarea {
    @include input-placeholder {
        color: green;
    }
}*/


/***** FLEX BOX ***** /

/* Flex box */

@mixin flexbox {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
}

/* %flexbox { @include flexbox; } */


@mixin inline-flex {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

/*  %inline-flex { @include inline-flex; } */



@mixin flex-direction($value: row) {
    @if $value == row-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: horizontal;
    } @else if $value == column {
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
    } @else if $value == column-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: vertical;
    } @else {
        -webkit-box-direction: normal;
        -webkit-box-orient: horizontal;
    }
    -webkit-flex-direction: $value;
    -moz-flex-direction: $value;
    -ms-flex-direction: $value;
    flex-direction: $value;
}

@mixin flex-wrap($value) {
    // No Webkit Box fallback.
    -webkit-flex-wrap: $value;
    -moz-flex-wrap: $value;
    @if $value == nowrap {
        -ms-flex-wrap: none;
    } @else {
        -ms-flex-wrap: $value;
    }
    flex-wrap: $value;
}

@mixin order($int: 0) {
    -webkit-box-ordinal-group: $int + 1;
    -webkit-order: $int;
    -moz-order: $int;
    -ms-flex-order: $int;
    order: $int;
}

@mixin flex-basis($value: auto) {
    -webkit-flex-basis: $value;
    -moz-flex-basis: $value;
    -ms-flex-preferred-size: $value;
    flex-basis: $value;
}

@mixin justify-content($value: flex-start) {
    @if $value == flex-start {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
    } @else if $value == flex-end {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
    } @else if $value == space-between {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
    } @else if $value == space-around {
        -ms-flex-pack: distribute;
    } @else {
        -webkit-box-pack: $value;
        -ms-flex-pack: $value;
    }
    -webkit-justify-content: $value;
    -moz-justify-content: $value;
    justify-content: $value;
}

/*  %inline-flex { @include inline-flex; } */

@mixin align-items($value: stretch) {
    @if $value == flex-start {
        -webkit-box-align: start;
        -ms-flex-align: start;
    } @else if $value == flex-end {
        -webkit-box-align: end;
        -ms-flex-align: end;
    } @else {
        -webkit-box-align: $value;
        -ms-flex-align: $value;
    }
    -webkit-align-items: $value;
    -moz-align-items: $value;
    align-items: $value;
}

@mixin align-self($value: auto) {
    // No Webkit Box Fallback.
    -webkit-align-self: $value;
    -moz-align-self: $value;
    @if $value == flex-start {
        -ms-flex-item-align: start;
    } @else if $value == flex-end {
        -ms-flex-item-align: end;
    } @else {
        -ms-flex-item-align: $value;
    }
    align-self: $value;
}

@mixin align-content($value: stretch) {
    // No Webkit Box Fallback.
    -webkit-align-content: $value;
    -moz-align-content: $value;
    @if $value == flex-start {
        -ms-flex-line-pack: start;
    } @else if $value == flex-end {
        -ms-flex-line-pack: end;
    } @else {
        -ms-flex-line-pack: $value;
    }
    align-content: $value;
}

@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex:  $values;
    -webkit-flex:  $values;
    -ms-flex:  $values;
    flex:  $values;
}
/* END FLEX BOX */


/* Clearfix */

@mixin cf() {
    *zoom: 1;
    &:before, &:after {
        content: '';
        display: table;
    }
    &:after {
        clear: both;
    }
}

/* End Clearfix */


/* Absolute center */

@mixin absolutecenter($axis: "both"){
    position:absolute;
    @if $axis == "y"{
        top: 50%;
        transform: translateY(-50%);
    }
    @if $axis == "x"{
        left: 50%;
        transform: translateX(-50%);
    }
    @if $axis == "both"{
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
    }
}

/* End Absolute center */

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

