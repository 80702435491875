html,body{
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}


body {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}

.wrapper {
  margin-top: 0;
}


@media (max-width:991px){
  #fb-root{
   display: none !important;
  }
}


.clean-safe-wrapper{
  display: block;
  position: absolute;
  top: 25px;
  right: 10px;
  width: 100%;
  max-width:100px;
  height: 100%;
  max-height: 100px;

  @media (min-width: 992px) {
    right: 100px;
    width: 100%;
    max-width:150px;
    height: 100%;
    max-height: 150px;
  }

  .clean-safe-img{
    width: 100%;
    height: auto;
    max-height: 100px;
    object-fit: cover;
    @media (min-width: 992px) {
      height: 100%;
      max-height: 150px;
    }
  }

}


.info-covid-wrapper{
    display: block;
    position: fixed;
    width: 100%;
    max-width: 130px;
    padding: 5px 5px 5px 5px;
    background: $secundary-color;
    top: 50%;
    z-index: 5;
    animation: pulseCovid 2s infinite ease-in-out;
    animation-play-state:running;
    border-top-right-radius: 25%;
    border-bottom-right-radius: 25%;
    -webkit-transform: translate(0,-5%);
    -ms-transform: translate(0,-5%);
    transform: translate(0,-5%);
  @media (min-width: 768px) {
    max-width: 150px;
    -webkit-transform: translate(0,-50%);
    -ms-transform: translate(0,-50%);
    transform: translate(0,-50%);
  }


    @media (min-width: 992px) {
      padding: 15px 10px 15px 15px;
      max-width: 220px;
    }

    .info-covid{
      position: relative;
      display: block;
      text-decoration: none;
    }
    .alert-text-title{
      font-size: 20px;
      color:#fff;
      font-weight: bold;
      margin: 0;
      padding: 0;

      @media (min-width: 768px) {
        font-size: 22px;
      }


      @media (min-width: 992px) {
        font-size: 28px;
      }
    }
    .alert-text-info{
      font-size:12px;
      color:#fff;
      margin: 5px 0 0 0;
      padding: 0;
      position:relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      align-items: center;
      .arrow-icon {
        display: block;
        width: 30px;
        height: 30px;
        svg {
          width: 20px;
          height: 30px;
          path {
            fill: #fff;
          }
        }
      }
      @media (min-width: 768px) {
        font-size: 14px;
      }

      @media (min-width: 992px) {
        font-size: 18px;
      }
    }
}

.section-mobile {
  margin-top: 0;
}

.title {
  position: relative;
  font-size: 36px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 5px;
  &:after {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    content: "";
    height: 8px;
    width: 90px;
    background-color: $primary-color;
  }
}

.subtitle {
  margin-top: 20px;
  margin-bottom: 20px;
  color: $dark-gray-color;
  font-size: 20px;
  font-weight: 300;
}

.text {
  margin-top: 20px;
  color: #a2a2a2;
}

.img-wrapper {
  margin: 20px 0px;
}

a {
  text-decoration: none;
  &:hover, &:active, &:visited {
    color: #FFF;
    text-decoration: none;
  }
}

.text-center {
  text-align: center;
}

header {
  h1 {
    font-size: 44px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 10px;
  }
  h2 {
    position: relative;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 40px;
    &:after {
      position: absolute;
      top: 40px;
      left: 50%;
      transform: translate(-50%, 0);
      content: "";
      height: 8px;
      width: 90px;
      background-color: $primary-color;
    }
  }
  h3 {
    position: relative;
    font-size: 44px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 10px;
    &:after {
      position: absolute;
      top: 90px;
      left: 50%;
      transform: translate(-50%, 0);
      content: "";
      height: 8px;
      width: 90px;
      background-color: $primary-color;
    }
  }
  h4 {

    font-size: 18px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 40px;

  }
  h5 {
    font-size: 44px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 10px;

  }
  h6 {
    position: relative;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 40px;
    &:after {
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translate(-50%, 0);
      content: "";
      height: 8px;
      width: 90px;
      background-color: $primary-color;
    }

  }
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: none;
  border-radius: 0;
}

.btn-primary {
  background-color: $secundary-color;
  @include transition();
  &:hover {
    @include transition();
    background-color: $primary-color;
  }
  &.success{
    background-color: rgba(172, 210, 38, 1);
    &:hover {
      background-color: rgba(172, 210, 38, 0.8);
    }
  }
}

.btn-bigger {
  font-size: 20px;
  text-align: center;
  padding: 20px 50px;
  text-transform: uppercase;
}

.btn-bigger02 {
  font-size: 20px;
  text-align: center;
  padding: 10px 50px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 15px;
}




.container-small, .container-extrasmall {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.icon-places {
  margin: auto;
  max-width: 140px;
}

.bg-map {
  background-color: #f5f3f3;
  background-image: url(../images/bg-map.svg);
  background-position: center center;
  background-repeat: no-repeat;
}

.list-map {
  background-color: rgba($header-color, 0.6);
  ul {
    li {
      a {
        line-height: 1.8;
        color: $black-color;
        font-size:14px;
        &:hover,
        &.country-active {
          color: $primary-color;
        }
        @media (max-width: 991px){
          font-size:16px;
        }
      }
      @media (max-width: 768px){
        &:not(:first-child){
          margin-top: 5px;
        }

      }
    }
  }
}


#homepage {
  margin-top: 40px;
}

.page-number {
  text-align: center;
  li {
    display:inline-block;
    margin:2px;
    width: 40px;
    height: 40px;
    a {
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      width: 100%;
      height: 100%;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0px;
      color: #545454;
      font-size: 16px;
      background: #ffffff;
      border: solid #b3b3b3 1px;

      text-decoration: none;
      @include transition();
      &:hover {
        @include transition();
        color: $secundary-color !important;
        text-decoration: none;
      }
    }
    span{
      line-height: 40px;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0px;
      color: #545454;
      font-size: 16px;
      padding: 10px 15px 10px 15px;
      border: solid #b3b3b3 1px;
      text-decoration: none;
      @include transition();
      background-color: $light-gray-color;
    }
  }
}



.wrapper-country {
  .country-color {
    fill: #ACD226;
  }
  .country-color-2 {
    fill: #69820f;
  }

}

.line-names {
  fill: none;
  stroke: #020203;
  stroke-width: 0.2835;
  stroke-miterlimit: 10;
}

@media (min-width: 1200px) {
  .container-small {
    width: 970px;
  }
  .container-extrasmall {
    width: 830px;
  }
}

.h1, .h2, .h3, h1, h2, h3 {
  margin: 0;
}

ul {
  padding-left: 0px;
  list-style-type: none;
}

.display-none {
  display: none;
}

.red {
  color: #d44950;
}

.green {
  color: #3c763d;
}

ul {
  margin-bottom: 0px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-60 {
  margin-top: 60px;
}

.margin-top-70 {
  margin-top: 70px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-bottom-60 {
  margin-bottom: 60px;
}

.margin-bottom-70 {
  margin-bottom: 70px;
}

.nopadding {
  padding: 0 !important;
}

.nopadding_left {
  padding-left: 0 !important;
}

.nopadding_right {
  padding-right: 0 !important;
}

.active {
  font-weight: bold;
}

.active2 {
  a {
    position: relative;
    font-weight: bold;
    color: $primary-color !important;
    &:after {
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translate(-50%, 0);
      content: "";
      height: 4px;
      width: 50px;
      background-color: $primary-color;
    }
  }

}

/*LOAD SPINNER STYLES*/

.load {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  background: $primary-color;
  overflow: hidden !important;
}

.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 300px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #FFF;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0.0)
  }
  50% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  }
  50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

/* destaques */
.bg-gray {
  background-color: $light-gray-color;
}

.tour-box {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-bottom: 20px;
  background-color: $header-color;
  @include transition();

  &:hover {
    @include transition();
    -webkit-box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.4);
    .tour-pic {
      img {
        -ms-transform: scale(1.2); /* IE 9 */
        -webkit-transform: scale(1.2); /* Safari */
        transform: scale(1.2); /* Standard syntax */
        @include transition();
      }
    }
    h3 {
      @include transition();
      color: $primary-color;
    }

    .tour-cta {
      .btn {
        @include transition();
        background-color: $primary-color;
      }
    }

  }
  .tour-pic {
    max-height: 40%;
    overflow: hidden;
    img {
      @include transition();
      -ms-transform: scale(1); /* IE 9 */
      -webkit-transform: scale(1); /* Safari */
      transform: scale(1); /* Standard syntax */
      min-width: 100%;
    }
  }
  .tour-text {
    padding: 15px;
  }
  .tour-city {
    font-size: 18px;
    font-weight: 300;
    color: $dark-gray-color;
  }
  h3 {
    @include transition();
    font-size: 20px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 20px;
    color: $dark-gray-color;
    display: block;
  }
  .tour-time {
    position: relative;
    font-size: 16px;
    font-weight: 300;
    color: $dark-gray-color;
    &:after {
      position: absolute;
      left: 0;
      bottom: -10px;
      content: "";
      height: 5px;
      width: 25px;
      background-color: $primary-color;
    }
  }
  .tour-intro {
    border-bottom: solid 1px $light-gray-color;
    color: $dark-gray-color;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 15px;
    font-weight: 300;
    height:60px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .tour-end {
    height: auto;
    margin: 0;
    display: table-cell;
    width: 50%;
    vertical-align: bottom;
  }
  h5 {
    margin-top: 0;
    font-size: 30px;
    font-weight: 500;
    color: $primary-color;
    margin-bottom: 0;
    span {
      font-size: 16px;
      font-weight: 300;
      color: $dark-gray-color;
      display: block;
    }
  }
  .tour-cta {
    display: table;
    width: 100%;
    height: auto;

    .btn {
      vertical-align: bottom;
    }
  }
}

.tour-cta {
  padding: 15px;
  padding-top: 0;
  .btn {
    @include transition();
    background-color: $secundary-color;
  }
}

.wrapper-404 {
  height: 70vh;
  padding-top: 150px;
  background-image: url('../images/banner-404.jpg');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  .content-404 {
    text-align: center;

    span {
      padding: 5px 20px;
      background-color: rgba($primary-color, 0.8);
      font-size: 36px;
      font-weight: 700;
      color: $header-color;
      text-transform: uppercase;
    }
    .btn-primary {
      font-size: 28px;
      text-transform: uppercase;
      background-color: $secundary-color;
      &:hover {
        background-color: $primary-color;
      }
    }

  }

}

.wrapper-message {
  height: 70vh;
  padding: 150px 5px;
  background-image: url('../images/banner-message.jpg');

  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  .content-message {
    text-align: center;

    span {
      padding: 5px 20px;
      background-color: rgba($primary-color, 0.8);
      font-size: 36px;
      line-height: 1.7;
      font-weight: 700;
      color: $header-color;
      text-transform: uppercase;

    }
    .second-text {
      font-size: 24px;
      line-height: 1.9;
    }
  }

}

#voucher {
  .voucher-ticket {
    font-size: 18px;
    font-weight: 300;
    display: flex;
    .ticket-img {
      -webkit-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.25);
      margin: auto;
      align-content: center;

      img{
        width: 100%;
        height: auto;
        object-fit: cover;
      }

    }
    .ticket-tittle {
      font-size: 36px;
      font-weight: 300;
      text-align: center;
      margin-bottom: 10px;
      margin-top: 10px;

    }

    h4 {
      font-size: 24px;
      font-weight: 300;
      text-align: center;
      margin-bottom: 10px;
      margin-top: 10px
    }
    .green-text {
      font-weight: 900;
      color: $primary-color;
    }
  }
  .bg-voucher {
    padding: 30px;
    background-image: url('../images/banner-voucher.jpg');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
    margin-top: 0;

  }

  h2 {
    margin-bottom: 20px;
    margin-top: 20px;
    &:after {
      display: none;
    }
  }
  .voucher-top {
    display: table;
    width: 100%;
  }
  .yellow-icons {
    background-color: rgba($secundary-color, 0.7);
    display: table-cell;
    width: 40%;
    padding: 20px;
    vertical-align: middle;
    .icons-content {
      padding: 20px;
      width: 100%;
      border-bottom: solid 1px $secundary-color;
      display: inline-block;
      vertical-align: middle;
      &:last-child {
        border-bottom: none;
      }
      .icon {

        svg {
          margin-right: 20px;
        }

        font-size: 16px;
        line-height: 1;
        color: $header-color;
        p {
          margin-bottom: 5px;
        }
      }

    }
  }


  .form-voucher {
    background-color: rgba($header-color, 0.7);
    display: table-cell;
    width: 60%;
    padding: 40px;
    vertical-align: top;
    h3 {
      margin-bottom: 20px;
      font-size: 24px;
      text-transform: uppercase;
    }


    .specialLabel {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
      width: 22px;
      span {
        font-weight: 700;
        text-transform: uppercase;
        font-size: em(14px);
      }
    }

    .form-group.select {
      position: relative;

      .checkbox-wrapper {
        &.no {
          opacity: 0.5;
          select {
            pointer-events: none;
          }
        }
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 767px){
          @include flex-wrap(wrap);
        }
        .check-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          [type="radio"]:not(:checked) + label,
          [type="radio"]:checked + label {
            cursor: pointer;
            height: initial;
            margin: 0;
            padding: 5px;
            min-width: 30px;
            min-height: 30px;
            position: relative;

            i {
              position: absolute;
              top: 50%;
              left: 50%;
              transition: all ease 0.3s;
              font-size: 14px;
              &.fa-square-o {
                transform: translate(-50%, -50%) scale(1);
              }
              &.fa-check-square-o {
                transform: translate(-50%, -50%) scale(0);
              }
            }
          }
          [type="radio"]:checked + label {
            i {
              &.fa-square-o {
                transform: translate(-50%, -50%) scale(0);
                opacity: 0;
              }
              &.fa-check-square-o {
                transform: translate(-50%, -50%) scale(1);
                opacity: 1;
              }
            }
          }
          [type="radio"]:not(:checked),
          [type="radio"]:checked {
            position: absolute;
            left: -9999px;
          }
        }
      }
    }
    .first-line {
      display: flex;
      justify-content: space-between;
      .form-group{
        margin-bottom: 0;
      }
    }
    .second-inline{
      display: flex;
      justify-content: space-between;
      .second-line {
        margin-bottom: 0;
         width: 50%;
        margin-top: 10px;
        &:first-child {
          width: calc(50% - 5px);
          margin-right: 5px;
        }
      }
        .form-control {
          width: 100%;
          margin: 0;

        }
    }
    .third-line {
      width: 100%;
      margin-top: 10px;
      .form-control {
        width: 100%;
        margin: 0;
      }
      margin-bottom: 0;
    }
    .fourth-inline {
      display: flex;
      justify-content: space-between;
      .fourth-line {
        width: 32%;
        margin-top: 10px;
        .form-control {
          width: 100%;
          margin: 0;
        }
      }
    }

    .form-control {
      height: 34px;
      padding: 6px 13px;
      font-size: 14px;
      line-height: 1.42857143;
      border-radius: 0;
    }

  }
  .voucher-btn {
    margin-top: 20px;
    text-align: center;
    .btn-primary {
      text-transform: uppercase;
      font-size: 20px;
    }
  }

}

.modal {
  z-index: 10050;
  .form-control {
    height: 34px;
    padding: 6px 13px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 0;
  }
}

.submit-voucher-form, .about-us--btn, .request-btn, .contacts-btn {
  background: $secundary-color;

  &:hover {
    background: $primary-color;
  }
}

.no-border::after {
  height: 0;
}

.isotope-element {
  float: left;
  width: 100%;
}


.wrapper-content-inside{
  background-color: #f5f5f5;
  padding-top: 40px;
  padding-bottom: 40px;
  @media (min-width: 1199px) {
    background: url(../images/background-fast-contacts.jpg) no-repeat center left  /   contain;
  }
}

.tour-highlight-img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.titleBudget{
  font-weight: 500;
  font-size: 30px;
  color:#000;
  margin: 0;
  padding: 0;
}

.subTitleBudget{
  font-weight: 500;
  font-size: 20px;
  color:#666666;
  margin:10px 0 20px 0;
  padding: 0;
}

.book-section--wrapper{
  .book-section{
    @include flexbox;
    @include flex-direction(column);
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    @media (min-width: 991px){
      @include flex-direction(row);
    }
  }

  .book-item{
    display: block;
    position: relative;
    width: 100%;
    max-width: 100%;
    margin-bottom: 25px;
    @media (min-width: 991px) {
      max-width: 50%;
      margin: 0;
    }

    picture {
      display: block;
      overflow: hidden;
    }

    img{
      width: 100%;
      min-height: 242px;
      height: auto;
      object-fit: cover;
      @include transition;
    }

    .book-item-wrapper{
      padding: 15px 0 0 0;
    }

    .bookTitle{
      font-size: 40px;
      text-transform: uppercase;
      color: $secundary-color;
      font-weight: 700;
      line-height: 1;
      margin: 0;
      padding: 0;

      &.green{
        color: $primary-color;
      }
    }

    .bookSubTitle{
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 500;
      color: $dark-gray-color;
      line-height: 1;
      margin-top: 1px;
    }

    .bookDescription{
      font-size: 14px;
      color: $dark-gray-color;
      line-height: 1.29;
      margin-top: 1px;
      p{
        width:100%;
        max-width:500px;
        margin: 0 auto;
      }
    }
  }

  .book-item-link:hover {
    .img-responsive {
      -webkit-transform: scale(1.2);
      -moz-transform:    scale(1.2);
      -ms-transform:     scale(1.2);
      -o-transform:      scale(1.2);
      transform:         scale(1.2);
    }

    .btn-primary {
      background-color: #acd226;
    }
  }
}

.pb-20{
  padding-bottom: 20px;
}


@media (max-width: 767px){

  .bottom-reference{
    padding-bottom: 450px !important;
  }

  .testimonial-item{
    img{
      max-width:150px;
      margin: 0 auto;
    }
  }
}


@keyframes pulseCovid{
  0% {
    -webkit-box-shadow:  0 0 0 0 $secundary-color;
    -moz-box-shadow:  0 0 0 0 $secundary-color;
    box-shadow:  0 0 0 0 $secundary-color;
  }

  50% {
    -webkit-box-shadow:  0 0 0 10px rgba($secundary-color,0.5);
    -moz-box-shadow:  0 0 0 10px rgba($secundary-color,0.5);
    box-shadow:  0 0 0 10px rgba($secundary-color,0.5);
  }

  100% {
    -webkit-box-shadow:  0 0 0 0 $secundary-color;
    -moz-box-shadow:  0 0 0 0 $secundary-color;
    box-shadow:  0 0 0 0 $secundary-color;
  }
}


