#sustainable{
  position: relative;
    background-image: url('../images/bg-turismo-sustentavel.jpg');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
  .leaf{
    position: relative;
    bottom:-15px;
  }
  .text-header{

    background-color: $header-color;
    margin: auto;
    padding: 40px;
    .text-header-p{
      padding-top: 60px;
      text-align: right;
    }
}
  .about-sustainable{
    ul{
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
      li{
        display: inline;
        img{
          margin: 20px 10px;
        }

      }
    }
    padding-top: 40px;
    padding-bottom: 40px;
    h3{
      font-size: 20px;
      color: $primary-color;
      font-weight: 700;
      margin-bottom: 30px;
      margin-top: 30px;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);

    }
    h4{
      color: #000000;
      font-size: 15px;
      font-weight: 500;
    }
    p{
      font-size: 15px;
    }
  }

}