.slick-slider {
    .slick-arrow {
        @include absolutecenter("y");
        z-index: 10;
        cursor: pointer;
        font-size: 40px;
        color: #FFF;
    }
    .fa-angle-left {
        left: 20px !important;
    }
    .fa-angle-right {
        right: 20px !important;
    }
}

.slick-next {
    right: 0px;
}

.slick-prev {
    left: 0px;
}

.slick-dots {
    bottom: 35px;
    li {
        button {
            @include transition;
            border-radius: 50%;
            background: turquoise;
            &:hover {
                background: $active-pagination-color;
            }
            &:before {
                color: transparent;
            }
        }
    }
    .slick-active {
        button {
            background: $active-pagination-color;
            &:before {
                opacity: 0;
                color: transparent;
            }
        }
    }
}

.slick-dots li.slick-active button:before {
    opacity: 0;
    color: transparent;
}

.paraxify{
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
}

.parallax-bg-home {
    margin-top: 40px;
    background-image: url(../images/banner-paraxify.jpg);
    min-height: 400px;
    width: 100%;
    background-repeat: no-repeat;
}




