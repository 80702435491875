#tour {
  .fixed {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 42px;
    z-index: 9;
  }
  .info-tour-mob {

    padding: 0;
    ul {
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 10px;

      li {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 400;
        padding-left: 20px;
        display: block;
        color: #000;
        &:before {
          content: "";
          height: 1em;
          width: 1em;
          display: block;
          float: left;
          margin-left: -1.5em;
          background-position: bottom;
          background-repeat: no-repeat;
          background-size: 100%;
          background-image: url("../images/pin.png");

        }
      }
    }

  }
  .wrapper-top {
    .image-tour {
      position: absolute;
      width: 100%;
      overflow-x: hidden;
      z-index: -1;
      img {
        min-width: 100%;
      }
    }
    .top-info {
      float: left;
      display: flex;
      width: 100%;
      overflow-x: hidden;
      justify-content: space-around;
      z-index: 2;
      top: 0;
      width: 100%;
      padding: 0;

      .info-tour {
        max-height: 505px;
        float: right;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
        padding: 0;
        background-color: rgba(0, 0, 0, 0.5);
        .info-tour--list {
          height: 370px;
          max-height: 370px;
        }
        ul {
          padding-top: 35px;
          padding-left: 25px;
          padding-right: 25px;
          margin-bottom: 30px;

          li {
            margin-bottom: 20px;
            font-size: 18px;
            font-weight: 400;
            padding-left: 20px;
            display: block;
            color: $header-color;
            &:before {
              content: "";
              height: 1em;
              width: 1em;
              display: block;
              float: left;
              margin-left: -1.5em;
              background-position: bottom;
              background-repeat: no-repeat;
              background-size: 100%;
              background-image: url("../images/pin.png");

            }
          }
        }

      }
      .info-tour-cta {
        padding: 0;
        width: 100%;
        bottom: 0;

        .tour-price {
          width: 48%;
          color: $header-color;
          height: auto;
          font-size: 30px;
          font-weight: 700;
          background-color: rgba(0, 0, 0, 0.7);
          float: left;
          padding: 10px 0;
          text-align: center;
          margin: 0;
          span {
            font-size: 14px;
            font-weight: 300;
          }

        }
        .tour-days {
          width: 48%;
          color: $header-color;
          height: auto;
          font-size: 30px;
          font-weight: 700;
          background-color: rgba($primary-color, 0.7);
          padding: 10px 0;
          text-align: center;
          float: right;
          margin: 0;
        }
        .btn-info {
          clear: left;
          clear: right;
          background-color: $primary-color;
          padding: 20px;
          width: 100%;
          font-size: 24px;
          text-transform: uppercase;

        }

      }
      .name-tour {
        float: left;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        .title-tour {
          padding: 20px;
          color: $header-color;
          h1 {
            font-size: 42px;
            text-shadow: 2px 2px 5px rgba(0, 0, 0, 1);
          }
          span {
            font-size: 24px;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
          }

        }

      }
    }

  }
  .wrapper-content {
    .top-line {
      width: 100%;
      margin: auto;
      border-bottom: solid 1px #e5e5e5;
      background-color: $header-color;
    }
    .info-menu {
      position: relative;
      .active {
        position: relative;
        &:after {
          position: absolute;
          left: 0;
          bottom: 0;
          content: "";
          width: 100%;
          height: 5px;
          background-color: $primary-color;
        }
      }
      .icon-tour {
        border-left: solid 1px #e5e5e5;
        position: absolute;
        top: 0;
        right: -15px;
        height: 42px;
        overflow-y: hidden;
        svg {
          height: 44px;
          width: auto;
        }

      }
      ul {
        list-style: none;

        li {

          display: inline-block;
          padding: 10px 15px;
          text-align: center;
          a {
            font-weight: 500;
            font-size: 16px;
            color: #747474;
            text-transform: uppercase;
            @include transition();
            &:hover {
              color: $primary-color;
              @include transition();
            }

          }

        }

      }

    }
    .form-tour {
      position: relative;
      .form-cta {
        //position: absolute;
        //top: 0;
        margin-top: -44px;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 5px;
        border: solid 1px #e5e5e5;
        z-index: 9;
        width:390px;
        background-color: #f7f7f7;
        .input-group-addon {
          padding: 6px 12px;
          font-size: 18px;
          line-height: 1;
          color: $primary-color;
          text-align: center;
          background-color: $header-color;
          border: 1px solid #dbd4d4;
          border-radius: 0;
          border-left: 0;
        }

        .form-control {
          display: block;
          padding: 5px 10px;
          font-size: 16px;
          line-height: 1.5;
          color: #747474;
          background-color: #fff;
          background-image: none;
          border: 1px solid #dbd4d4;
          border-radius: 0;
          -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
          -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
          -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
          transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

        }
        .form-group {
          margin-bottom: 8px !important;
        }

      }
      .people-field {
        display: flex;
        justify-content: space-between;
        align-content: flex-start;
        .half-field {
          width: 48%;
        }

      }

    }
    .fixed-top {
      position: fixed !important;
      top: 95px !important;
    }
    .esconde {
      padding: 14px;
      min-width: 100% !important;
    }

    .form-tour-mobile {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 16;

      .esconde2 {
        @include transition();
        width: 100%;
        background: #FFF;
        height: auto;

      }

    }
    .btn-cta {
      font-size: 18px;
      font-weight: 500;
      color: $header-color;
      width: 100%;
      padding: 15px;
      background-color: $secundary-color;
      @include transition();
      &:hover {
        @include transition();
        background-color: $primary-color;
      }
    }
    .full-gray {
      background: #f5f5f5; /* Old browsers */
      background: -moz-linear-gradient(left, #f5f5f5 75%, #ffffff 76%, #ffffff 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, #f5f5f5 75%, #ffffff 76%, #ffffff 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, #f5f5f5 75%, #ffffff 76%, #ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5f5f5', endColorstr='#ffffff', GradientType=1); /* IE6-9 */
      margin-bottom: 30px;
    }
    .tour-highlight {
      font-weight: 300;
      padding-top: 50px;
      padding-bottom: 10px;

      ul {

        li {
          margin-bottom: 20px;
          font-size: 16px;
          font-weight: 400;
          padding-left: 10px;
          display: block;
          color: #666666;
          &:before {
            font-family: FontAwesome;
            content: "\f00c";
            display: inline-block;
            padding-right: 3px;
            vertical-align: middle;
            color: $primary-color;
          }
        }
      }
    }


    .tour-map{
      display: block;
      position: relative;
      width:100%;
      max-width: 680px;
      height: 100%;
      padding:15px 0;

      .tour-map-img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

    }

    .tour-journey {
      position: relative;
      h4 {
        font-size: 24px;
        text-align: left;
        padding: 15px;
        background-color: #f7f7f7;
        font-weight: 500;
        text-transform: uppercase;
        &:after {
          display: none;
        }
      }
      .description-group {
        padding-bottom: 30px;
        position: relative;
        &:before {
          content: "";
          width: 1px;
          height: 110%;
          position: absolute;
          top: 0;
          left: -42px;
          border-left: 2px dotted $mid-gray-color;

        }
        &:last-child {
          padding-bottom: 0;
          &:before {
            display: none;
          }
        }

        .day {
          position: relative;
          font-size: 24px;
          font-weight: 500;
          color: $primary-color;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: -70px;
            width: 56px;
            height: 56px;
            background-image: url("../images/pin2.png");
          }
        }
        .title-step {
          font-size: 18px;
          text-transform: uppercase;
        }
        .text-description {
          font-size: 14px;
          line-height: 22px;
        }
      }

    }
    .banner-prices {
      .banner-months {
        position: relative;

        .slick-prev {
          right: auto;
          left: 10px !important;

        }
        .slick-next {
          right: 10px !important;
          left: auto;
        }
        .slick-prev, .slick-next {
          position: absolute;
          top: 28px;
          z-index: 14;
        }

        .price-top {
          background-color: $primary-color;
          padding: 10px;
          text-align: center;
          margin-bottom: 2px;
          font-size: 24px;
          color: $header-color;
        }
        .price-table {
          width: 100%;
          color: #000;
          font-size: 16px;
          .detail-small {
            font-size: 12px;
            font-weight: 300;
          }
          th {
            background-color: #e7e8e2;
            padding: 10px;
            border: solid 1px #dcdcdc;
            text-transform: uppercase;
            text-align: center;

          }
          tr {
            border: solid 1px #dcdcdc;

          }
          td {
            border: solid 1px #dcdcdc;
            text-align: center;
            padding: 5px 15px;
            span {
              font-size: 12px;
              font-weight: 300;
            }
          }
          .city {
            font-weight: 700;
            text-align: left;
          }
          .prices {
            font-weight: 700;
            color: $primary-color;
          }

        }
      }
    }
    .conditions {

      .green-items {
        margin-bottom: 40px;
        h5 {
          color: $primary-color;
          font-size: 18px;
          text-transform: uppercase;
          margin-bottom: 20px;
        }
        ul {

          li {
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: 400;
            padding-left: 20px;
            display: block;
            color: #666666;
            padding-bottom: 10px;
            border-bottom: solid 1px #e5e5e5;
            &:before {
              content: "";
              height: 1em;
              width: 1em;
              display: block;
              float: left;
              margin-left: -1.5em;
              background-position: bottom;
              background-repeat: no-repeat;
              background-size: 100%;
              background-image: url("../images/check.png");
            }

          }

        }
      }
      .red-items {
        margin-bottom: 40px;
        h5 {
          color: #e33d08;
          font-size: 18px;
          text-transform: uppercase;
          margin-bottom: 20px;
        }
        ul {

          li {
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: 400;
            padding-left: 20px;
            display: block;
            color: #666666;
            padding-bottom: 10px;
            border-bottom: solid 1px #e5e5e5;
            &:before {
              content: "";
              height: 1em;
              width: 1em;
              display: block;
              float: left;
              margin-left: -1.5em;
              background-position: bottom;
              background-repeat: no-repeat;
              background-size: 100%;
              background-image: url("../images/cross.png");
            }

          }

        }
      }

    }

  }
}

.datepicker-days .day {
  font-weight: bold;
  background: $primary-color;
  color: $background-color;

  &:hover {
    background: $primary-color !important;
  }
}

.datepicker-days .day.disabled {
  font-weight: normal;

  &:hover {
    background: none !important;
  }
}

.svg-small--text {
  font-size: 26px;
}