.gray-top{
  height:100px;
  background-color: #efecec;
}
.help{
  a{
    color: $primary-color;
    &:hover{
      color: $secundary-color;
    }
  }
  .help-intro{
    ul{margin-bottom: 0;
      li{
        margin-bottom: 0;
        &:before{
          font-family: FontAwesome;
          content: "\f00c";
          display: inline-block;
          padding-right: 3px;
          vertical-align: middle;
          color: $primary-color;
        }
      }
    }
  }
  .help-green{
    color: $primary-color;
  }
.top_title{
  position: relative;
  min-height: 100px;
  overflow-x: hidden;
  .title_help_topic{
    position: absolute;
    z-index: 5;
    top:40%;
    left: 20px;
    h2{
      margin-bottom: 0;
      font-size: 30px;
      color: $black-color;
      margin-bottom: 20px;
      position: relative;
      &:after {
        position: absolute;
        left: 0;
        bottom: -10px;
        content: "";
        height: 5px;
        width: 45px;
        background-color: $black-color;
      }
    }
  }

}
  h5{
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  h3{
    font-size: 18px;
    font-weight: 700;
    span{
      font-size: 24px;
      color: $primary-color;
      padding-right: 5px;
      border-right: solid 1px #ccc;
    }
    a{
      color: $black-color;
      float: left;
      padding-left: 15px;
      width: 100%;
      @include flexbox;
      @include justify-content(space-between);
      @include align-items(center);
      &:hover{
        color: $primary-color;
      }
    }
     }
ul{
  margin-bottom: 40px;
  li{
    color: #808080;
    margin-bottom: 20px;
    h2{
      font-weight: 700;
      border-bottom: solid 1px #ccc;
      padding-bottom: 5px;
      i{
        font-size: 24px;
        color: $primary-color;
        padding-right: 15px;
        border-right: solid 1px #ccc;
      }
      span{
        font-size: 18px!important;
        margin-left: 15px;
        color: $black-color;
        border-right: hidden;
      }
    }
    ul{margin-bottom: 0;
      li{
        margin-bottom: 0;
        margin-left: 20px;
        &:before{
          font-family: FontAwesome;
          content: "\f054";
          display: inline-block;
          padding-right: 3px;
          vertical-align: middle
        }
      }
    }
  }
}

}
.submenu-help{
    position: relative;
    .help-title{
      height: 60px;
      position: absolute;
      top: -60px;
      width: 100%;
      z-index: 4;
      background-color: $primary-color;
      color: $header-color;
      text-align: center;
      p{
        font-size: 20px;
        text-transform: uppercase;
        margin-top: 17px;
      }
    }
    .help-list{
      padding: 35px;
      border: solid 1px #e5e5e5;
      background-color: $header-color;
      text-align: center;
      font-size: 16px;
      margin-bottom: 20px;
      ul{
        li{
          margin: 0;
          padding-top: 20px;
          padding-bottom: 20px;
          border-bottom: solid 1px $light-gray-color;
          &:first-child{
            padding-top: 0;
          }
          &:last-child{
            padding-bottom: 0;
            border-bottom: none;
          }
          a{
            color: $black-color;
            &:hover{
              color: $primary-color;
            }
          }
          .active{
            font-weight: 700;
          }
        }
      }
    }
  }

#faq{
  .panel-body {
    border-top: none!important;
  }
  .panel {
    margin-bottom: 0;
    background-color: #fff;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: 0 0 0 rgba(0,0,0, 0);
    box-shadow: 0 0 0 rgba(0,0,0, 0);
  }
  .panel-heading {
    color: #333;
    background-color: #FFF!important;
    padding: 10px 15px;
    border-bottom: solid 1px #ccc;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

  }
.panel-heading i{
    font-size: 12px;
    float: right;
    color: grey;
  }

}

.panel-number {
  font-size: 24px;
  color: $primary-color;
  padding-right: 5px;
  border-right: solid 1px #ccc;
  float: left;
  display: block;
}

.align-flex {
  @include flexbox;
  @include align-items(center);
}

.faq-title {
  float: left;
  width: 100%;
  color: #333;
  font-size: 18px;
  font-weight: bold;
}

.help-body {
  ul {
    list-style: inside;
  }
}