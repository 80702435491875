/* Large Devices, Wide Screens */
@media (min-width: 1400px) and (max-width: 1920px) {
  #contactos {
    .end-gray {
      .gray-bg {
        height: 230px;
        width: 50%;
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  #contactos {
    .end-gray {
      .gray-bg {
        height: 240px;
        width: 50%;

      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .pic-2 {
    min-height: 331px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #budget {
    .wrapper-top {
      padding-bottom: 150px;
      .pin-step:nth-child(2) {
        margin-top: 30px;
      }
      .pin-step:nth-child(3) {
        margin-top: 30px;
      }
      .pin-step:nth-child(4) {
        margin-top: 30px;
      }

      .pin-step {
        margin-top: 30px;
        height: 60px;
        overflow-y: hidden;

        .pin-title {
          width: 90%;
          font-size: 18px;
          font-weight: 500;
        }
        .pin-svg {
          float: left;
          width: 10%;
          height: auto;
          .esconde {
            display: none;
          }
        }
      }

    }
    .wrapper-content {
      .half-field {
        width: 52%;
        float: left;
        margin-right: 2%;

      }
      .half-field1 {
        width: 22%;
        float: left;
        margin-right: 2%;

      }
      .half-field2 {

        width: 22%;
        float: left;
        margin-right: 0%;

      }
    }
  }
  #contactos {
    .end-gray {
      .gray-bg {
        height: 230px;
        width: 50%;

      }
    }
  }
  #tour {
    .wrapper-top {
      .top-info {
        .info-tour {

          ul {
            padding-top: 40px;
            padding-left: 25px;
            padding-right: 25px;
            margin-bottom: 50px;
            li {
              margin-bottom: 20px;
              font-size: 16px;;
            }
          }
        }
        .info-tour-cta {
          padding: 0;
          width: 100%;
          .tour-price {
            font-size: 28px;
            span {
              font-size: 12px;
            }
          }
          .tour-days {
            font-size: 28px;
          }
          .btn-info {
            font-size: 20px;
          }
        }
        .name-tour {
          .title-tour {
            h1 {
              font-size: 36px;
            }
            span {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  .banner-home {
    .form-search {
      top: 55%;
      left: 50%;
      transform: translate(-50%, -45%);
    }
  }

  .form-banner {
    padding: 0 10px;
  }

  #homepage {

    .top-sells {

      .top-tour-price {

        top: 6px;
        right: 6px;
        padding: 3px 6px;
        font-size: 15px;
      }
      .top-tour-title {
        padding: 4px;
        h5 {
          font-size: 15px;
          padding-bottom: 5px;
        }
        .tour-cta {
          font-size: 12px;
        }
      }
    }
  }
  .team-card {

    ul {

      li {

        div {
          width: 100%;
          padding: 10px;
          border: solid 1px #cccccc;
          font-size: 16px;
          font-weight: 500;
          color: #000000;
          span {
            display: block;
            font-weight: 300;
          }
        }

        &:first-child {
          float: none;
          div {
            width: 100%;
            span {

              font-size: 28px;
              color: $primary-color;
              font-weight: 900;
            }
          }
        }
      }
    }
    .barcode-div {
      padding-right: 0;
      padding-left: 0;
      svg {
        height: 300px;
        width: auto;
      }
    }

    .ticket-board {
      font-size: 40px;
      color: #e8e8e8;
    }
    h4 {
      margin-bottom: 20px;
      position: relative;
      &:after {
        position: absolute;
        left: 0;
        bottom: -10px;
        content: "";
        height: 5px;
        width: 25px;
        background-color: #acd226;
      }
    }
  }
}

/* Medium Devices, Desktops */
@media (max-width: 991px) {

  #voucher {
    .yellow-icons {
      display: block;
      width: 100%;
      .icons-content {
        padding: 10px;
        width: 100%;
        &:last-child {
          border-bottom: none;
        }
        .icon {
          svg {
            margin-right: 10px;

            float: left !important;

          }
        }
      }
    }
    .form-voucher {
      width: 100%;
      display: block;
      h3 {
        font-size: 20px;
      }

    }
    .voucher-btn {
      margin-top: 20px;
      .btn-primary {
        font-size: 16px;
      }
    }
  }

  #blog {
    h1 {
      margin-bottom: 30px;
    }

    .blog-gray {
      .gray-text {
        padding: 20px;
        font-size: 13px;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: space-between;
        .gray-title {
          font-size: 17px;
          margin-bottom: 10px;
        }
        .btn {
          min-width: 100%;
          justify-self: fl;
        }
      }

    }

  }
  #budget {

    .wrapper-top {
      padding-bottom: 200px;
      .pin-step:nth-child(2) {
        margin-top: 20px;
      }
      .pin-step:nth-child(3) {
        margin-top: 20px;
      }
      .pin-step:nth-child(4) {
        margin-top: 20px;
      }

      .pin-step {
        margin-top: 20px;
        height: 60px;
        overflow-y: hidden;

        .pin-title {
          width: 80%;
          font-size: 18px;
          font-weight: 500;
        }
        .pin-svg {
          float: left;
          width: 20%;
          height: auto;
          .esconde {
            display: none;
          }
        }
      }

    }
    .wrapper-content {
      .half-field {
        width: 100%;
        float: none;
        margin-right: 0%;

      }
      .half-field1 {
        width: 49%;
        float: left;
        margin-right: 2%;

      }
      .half-field2 {

        width: 49%;
        float: left;
        margin-right: 0%;

      }
    }
  }
  #payment, #faq, #conditions {
    h1 {
      margin-bottom: 30px;
    }

  }
  #contactos {
    h1 {
      margin-bottom: 30px;
    }
    .end-gray {
      .gray-bg {
        height: 220px;
        width: 100%;
      }
    }
  }
  #sustainable {
    h1 {
      margin-top: 120px;
    }

    .leaf {
      position: relative;
      bottom: 0;
    }
    .text-header {
      padding: 20px;
      .text-header-p {
        padding-top: 20px;
        text-align: center;
      }
    }
  }

  #about {
    .team-card {
      .list-field {
        .resume {
          height: 100%;
          @include transition();
          .arrow-resume {
            @include transition();
            content: "";
            z-index: 2;
            background-color: $primary-color;
            width: 15px;
            height: 15px;
            position: absolute;
            top: 35%;
            left: -8px;
            -ms-transform: rotate(-5deg); /* IE 9 */
            -webkit-transform: rotate(-45deg); /* Safari */
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  #tour {
    .wrapper-top {
      .top-info {
        .info-tour {

          ul {
            padding-top: 50px;
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 40px;
            li {
              margin-bottom: 20px;
              font-size: 15px;
            }
          }

        }
        .info-tour-cta {
          padding: 0;
          width: 100%;
          .tour-price {
            font-size: 24px;
            span {
              font-size: 10px;
            }
          }
          .tour-days {
            font-size: 24px;
          }
          .btn-info {
            font-size: 16px;
          }
        }
        .name-tour {
          height: 480px;
          .title-tour {

            h1 {
              font-size: 32px;
            }
            span {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  header {
    padding: 0 0;
    .logo-circles {
      img {
        width: auto;
        height: 100%;
        max-height: 35px;
        margin-right: 4px;
      }
    }

    .logo {
      display: inline-block;
      vertical-align: middle;
      flex: initial !important;
      z-index: 115;
      position: relative;
      a {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 0px;
      }
      span {
        font-size: 20px;
      }
      p {
        font-size: 8px;
      }
    }
  }
  .menu-items {
    position: fixed;
    z-index: 105;
    width: 100%;
    height: 100vh;
    background-color: #f5f6f3;
    display: none;
    top: 55px;
    padding-right: 0 !important;
    ul {
      z-index: 106;
    }
    li {
      width: 100%;
      display: block;
      text-align: center;
      padding: 8px 10px !important;
      border-bottom: solid 1px #e7e8e4;
      background-color: #f5f6f3;
      &:nth-child(6), &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10) {
        background-color: #d5d5d5;
      }
      a {
        color: $dark-gray-color !important;
      }
    }
    .banner-home {

      h1 {
        font-size: 36px;
        margin-bottom: 15px;

        span {
          display: inline;
          font-weight: 900;
        }
      }
    }
  }
  .form-banner {
    .btn {
      width: 10%;
      margin-bottom: 0;
      margin-top: 1px;
      padding: 24px 8px !important;
      font-size: 14px;
      margin-right: 0;
    }
    padding: 10px 20px;
    .form-fields {
      margin-bottom: 0;
      margin-top: 1px;
      padding: 5px;
      background-color: #fff;
      height: auto;
      margin-right: 1px;
      &:nth-child(4) {
        width: -moz-calc(40% - 1px);
        /* WebKit */
        width: -webkit-calc(40% - 1px);
        /* Opera */
        width: -o-calc(40% - 1px);
        /* Standard */
        width: calc(40% - 1px);
        margin-right: 1px;
      }
      &:nth-child(2), &:nth-child(3) {
        width: -moz-calc(25% - 1px);
        /* WebKit */
        width: -webkit-calc(25% - 1px);
        /* Opera */
        width: -o-calc(25% - 1px);
        /* Standard */
        width: calc(25% - 1px);
        margin-right: 1px;
      }

    }
    .title-input {
      font-size: 17px !important;

    }
    .input-group-addon {
      font-size: 19px;
      top: -25px;

    }
  }

  .banner-home {
    .form-search {
      top: 55%;
      left: 50%;
      transform: translate(-50%, -55%);
      h2 {
        font-size: 32px;
        margin-bottom: 15px;

        span {
          display: block;
          font-weight: 900;
        }
      }
    }
  }
  .pic-2 {
    min-height: 253px;
  }

  .header-bg--wrapper {
    display: none;
    .container, .row, .col-xs-12 {
      padding: 0 !important;
    }

    .menu-wrapper ul {
      display: block !important;
      position: initial !important;
    }
  }

  .header-bg--wrapper .menu-wrapper ul li {
    display: block !important;
    height: 35px !important;
    line-height: 35px !important;
    flex: none !important;
    font-size: 15px !important;
    a {
      padding: 0;
      border-bottom: none;
    }
  }

  .hamburger {
    top: 25px;
  }

  header .logo a {
    margin-top: -11px !important;
    padding: 0 !important;
  }

  .text-logo-new {
    margin: 62px 0 0 -138px;
  }
}

/* Small Devices, Tablets */
@media (max-width: 767px) {
  .form-banner .form-fields:nth-child(4) {
    width: 100%;
  }

  .logo-slogan {
    display: none;
  }

  .new-header--wrapper {
    width: auto;
  }

  .heading-style {
    font-size: 34px;
  }

  .wrapper {
    margin-top: 90px;
  }
  #voucher {
    margin-top: 20px;
    .bg-voucher {
      padding: 0;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-top: 20px;
    }

    .form-voucher {
      display: block;
      width: 100%;
      padding: 10px;
      h3 {
        font-size: 24px;
        text-align: center;
      }
      .specialLabel {
        display: block;
        margin:0 auto 10px auto;
        span {
          position: relative;
        }
      }
      .first-line {
        display: block;
        .form-group{
          width: 100%;
          margin-bottom: 15px;
        }
      }
      .second-inline{
        display: block;
        .second-line {
          width: 100%;
          &:first-child {
            width: 100%;
            margin-right: 0;
          }
        }
        .form-control {
          width: 100%;
          margin: 0;
        }
      }

      .fourth-inline {
        display: block;
        .fourth-line {
          width: 100%;
          .form-control {
            width: 100%;
            margin: 0;
          }
        }
      }

    }
    .voucher-btn {
      margin-top: 20px;
      .btn-primary {
        font-size: 16px;
      }
    }
  }
  #blog {
    margin-top: 20px;
    h1 {
      margin-bottom: 30px;
    }


  }

  .wrapper-404 {
    height: 60vh;
    padding-top: 200px;
    background-image: url('../images/banner-404.jpg');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
    .content-404 {
      text-align: center;

      span {
        padding: 5px 10px;
        font-size: 24px;

      }
      .btn-primary {
        font-size: 22px;
        text-transform: uppercase;
        background-color: $secundary-color;
        &:hover {
          background-color: $primary-color;
        }
      }

    }

  }
  .wrapper-message {
    height: 60vh;
    padding: 200px 5px;
    .content-message {

      span {
        padding: 5px 10px;
        font-size: 24px;

      }
      .second-text {
        font-size: 18px;
      }
    }

  }
  #budget {

    .wrapper-top {
      h1 {
        margin-top: 20px;
      }
      padding-bottom: 200px;
      .pin-step:nth-child(2) {
        margin-top: 10px;
      }
      .pin-step:nth-child(3) {
        margin-top: 10px;
      }
      .pin-step:nth-child(4) {
        margin-top: 10px;
      }

      .pin-step {
        margin-top: 10px;
        height: 60px;
        overflow-y: hidden;

        .pin-title {
          width: 80%;
          font-size: 18px;
          font-weight: 500;
        }
        .pin-svg {
          float: left;
          width: 20%;
          height: auto;
          .esconde {
            display: none;
          }
        }
      }

    }
  }
  #payment, #faq, #conditions {
    h1 {
      margin-bottom: 30px;
    }
    margin-top: 20px;
  }
  .submenu-help {
    .help-list {
      padding: 15px;
      border: none;
      margin-bottom: 0;
    }
  }
  #conditions,#payment,#faq,#privacy,#condition{
    .help{
      margin-bottom: 25px;
    }
    .row {
      @include flexbox;
      @include flex-direction(column-reverse);
    }
  }

  #contactos {
    h1 {
      margin-bottom: 30px;
    }
    margin-top: 20px;
    .end-gray {
      .gray-bg {
        height: 220px;
        width: 100%;
      }
    }
    .form-contacts {
      margin-top: 20px;
      position: relative;
      .form-title {
        height: 60px;
        position: absolute;
        top: 0;
      }
      .form-fields {
        margin-top: 60px;
        border: none;
      }
    }

  }
  .section-mobile {
    margin-top: 20px;
  }
  #about {
    .title {
      margin-top: 0;
    }
    .title2 {
      margin-top: 20px;
    }
    .header-about {
      background-color: rgba($header-color, 0.8) !important;
    }

    .team-card {
      .list-field {
        padding-left: 0;
        padding-right: 0;
        .resume {
          height: 100%;
          width: 100%;
          @include transition();
          .arrow-resume {
            @include transition();
            content: "";
            z-index: 2;
            background-color: $primary-color;
            width: 15px;
            height: 15px;
            position: absolute;
            top: -7px;
            left: 50%;
            -ms-transform: rotate(-5deg); /* IE 9 */
            -webkit-transform: rotate(-45deg); /* Safari */
            transform: rotate(-45deg);
            margin-left: -7px;
          }
        }
      }

      padding: 15px;
      background-color: $header-color;
      border-radius: 5px;
      -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.53);
      -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.53);
      box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.53);
      .dashed-line {
        border-left: none;
        padding-right: 0;
      }
      ul {

        li {
          display: inline;
          margin: 0;
          padding: 0;
          float: none;
          width: 100%;

          div {
            width: 100%;
            padding: 10px;
            border: solid 1px #cccccc;
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            span {
              display: block;
              font-weight: 300;
            }
          }

          &:first-child {
            float: none;
            div {
              width: 100%;
              span {
                font-size: 28px;
                color: $primary-color;
                font-weight: 900;
              }
            }
          }
        }
      }
      .barcode-div {
        padding-right: 0;
        padding-left: 0;
        svg {
          height: auto;
          width: auto;
        }
      }

      .ticket-board {
        font-size: 40px;
        color: #e8e8e8;
      }
      h4 {
        margin-bottom: 20px;
        position: relative;
        &:after {
          position: absolute;
          left: 0;
          bottom: -10px;
          content: "";
          height: 5px;
          width: 25px;
          background-color: #acd226;
        }
      }

    }

  }

  #tour {
    .modal-body {
      position: relative;
      padding: 10px;
    }
    .wrapper-content {
      .full-gray {
        background: #f5f5f5;
        margin-bottom: 20px;
      }
      .tour-highlight {
        background-color: #f5f5f5;
        padding-top: 20px;
        padding-bottom: 0;
      }
      .banner-prices {
        .banner-months {
          .slick-prev, .slick-next {
            top: 18px;
          }
          .slick-prev {

            left: 5px !important;

          }
          .slick-next {
            right: 5px !important;

          }
          .price-top {
            background-color: $primary-color;
            padding: 5px;
            text-align: center;
            margin-bottom: 2px;
            font-size: 18px;
            color: $header-color;
          }
          .price-table {
            width: 100%;
            color: #000;
            font-size: 13px;
            .detail-small {
              font-size: 9px;
              font-weight: 300;
            }
            th {
              background-color: #e7e8e2;
              padding: 10px;
              border: solid 1px #dcdcdc;
              text-transform: uppercase;
              text-align: center;
              font-size: 13px;

            }
            tr {
              border: solid 1px #dcdcdc;
            }
            td {

              border: solid 1px #dcdcdc;
              text-align: center;
              padding: 15px 5px;
            }
            .city {
              font-weight: 700;
              text-align: left;
            }
            .prices {
              font-weight: 700;
              color: $primary-color;
            }

          }
        }
      }
    }

    .fixed {
      position: relative;
    }
    .wrapper-content {
      .tour-journey {

        .description-group {
          padding-bottom: 30px;
          position: relative;
          &:before {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            top: 0;
            left: -142px;
            border-left: none;
          }

          .day {
            font-size: 20px;
            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: -270px;
              width: 0;
              height: 0;
              background-image: url("../images/pin2.png");
            }
          }
          .title-step {
            font-size: 16px;
            text-transform: uppercase;
          }
          .text-description {
            font-size: 14px;
            line-height: 20px;
          }
        }

      }
      .form-tour {
        position: relative;
        .form-cta {
          position: relative;
          border: none;
          width: 100%;
          margin-top: 0 !important;
          font-size: 14px;
          padding-top: 1px;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 5px;
          background-color: $header-color;
          .form-control {
            font-size: 14px;
          }
        }

        .people-field{
          display: block;
          .half-field{
            width: 100%;
          }
        }

      }
    }
    .wrapper-top {
      min-width: 100%!important;
      overflow-x: hidden;
      .image-tour {
        position: relative;
        z-index: -1;
        img{
          min-width: 110%;
        }
      }
      .top-info {
        display: block;
        margin-top: -130px;

        .info-tour {
          position: relative;
          float: left;


        }
        .info-tour-cta {
          padding: 0;
          width:100%;
          .tour-price {
            font-size: 24px;
            width: 50%;
            span {
              font-size: 10px;
            }
          }
          .tour-days {
            font-size: 24px;
            width: 50%;
          }
          .btn-info {
            font-size: 16px;
          }
        }
        .name-tour {
          height:130px !important;
          .title-tour {

            h1 {
              font-size: 24px;
            }
            span {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .shadow-header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
  }
  #homepage {
    margin-top: 0;
    header {
      h3 {
        font-size: 30px;
        padding: 10px;
        margin-bottom: 30px;
        &:after {
          top: 45px;
          left: 50%;
          transform: translate(-50%, -45%);
        }
      }
      h4 {
        position: relative;
        font-size: 15px;
        margin-bottom: 40px;
      }
    }
  }
  .banner-home {
    max-height: 750px;
    .form-search {
      position: relative;
      top: -47px;
      left: 50%;
      transform: translate(-50%, -47px);
      h2 {
        font-size: 32px;
        margin-bottom: 25px;

        span {
          display: block;
          font-weight: 900;
        }
      }
    }
  }
  .form-banner {
    .btn {
      margin-bottom: 0;
      margin-top: 0;
      width: 100%;
      font-size: 16px;
    }
    padding: 10px 10px;
    .form-fields {
      margin-bottom: 1px;
      margin-top: 0;
      padding: 5px;
      background-color: #fff;
      height: auto;
      margin-right: 0;
      &:nth-child(1) {
        width: 100%;
        margin-right: 0;
      }
      &:nth-child(2) {
        width: -moz-calc(50% - 1px);
        /* WebKit */
        width: -webkit-calc(50% - 1px);
        /* Opera */
        width: -o-calc(50% - 1px);
        /* Standard */
        width: calc(50% - 1px);
        margin-right: 1px;
      }
      &:nth-child(3) {
        width: 50%;
        margin-right: 0;
      }

    }
  }
  .newsletter {
    padding-top: 15px;
    padding-bottom: 30px;
    border-bottom: solid 1px $mid-gray-color;
    .form-group {
      margin-bottom: 1px;
    }
    .news-form {
      width: 100%;
      margin-right: 0;
      .form-control {
        width: 100%;
      }
      &:last-child {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 20px;
        .btn {
          width: 100%;
        }
      }
    }
    h4 {
      text-align: center;
      position: relative;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 20px;

    }
    ul.social-network {
      margin-top: 20px;
      text-align: center;
    }
  }
  .menu-footer {
    .pme {
      height: 100px;
      margin-top: 15px;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

    }
    h5 {
      position: relative;
      text-align: center;
      margin-bottom: 20px;

      &:after {
        left: -moz-calc(50% - 12px);
        /* WebKit */
        left: -webkit-calc(50% - 12px);
        /* Opera */
        left: -o-calc(50% - 12px);
        /* Standard */
        left: calc(50% - 12px);
      }
    }
    ul {
      margin-bottom: 15px;
    }
    li {
      text-align: center;
      margin-bottom: 15px;

    }
  }

  .pic-2 {
    margin-left: 0 !important;
  }
}

@media (max-width: 650px) {
  .banner-home {
    max-height: 650px;
  }
}

@media (max-width: 550px) {
  .banner-home {
    max-height: 600px;
  }
  #homepage {
    .blog-list-post {
      width: 100%;
    }
  }
  .subtitle {
    font-size: 18px;
  }
  #blog {
    .blog-gray {
      background-color: #efecec;
      color: #000000;
      .gray-highlight {
        background-color: $header-color;
      }
      .gray-text {
        width: 100%;
        padding: 20px;
        .gray-title {
          font-size: 16px;
          margin-bottom: 10px;
        }
      }

      .gray-pic {
        width: 100%;
        float: none;
        img {
          float: none;
        }

      }
    }
    .blog-list {
      .blog-list-post {
        width: 100%;
      }

      .list-title {
        margin-bottom: 20px;
        h4 {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 20px;
          text-align: left;
        }

      }

    }
    .blog-menu {
      .menu-mobile {
        display: block;
      }
      .menu-desktop {
        display: none;
      }

    }
  }

  header {
    h5 {
      font-size: 26px;
    }
    h6 {
      position: relative;
      font-size: 15px;
      &:after {
        top: 50px;
      }
    }
  }
}

@media (max-width: 550px) {
  .wrapper-message {
    height: 70vh;
    padding: 100px 5px;
  }
  .circuit {
    min-width: 100%;
  }
  #blog {
    .footer-gray {
      .pic-right, .pic-left {
        width: 100% !important;
      }
    }
  }
}

@media (max-width: 991px){
  #blog {
    .footer-gray {
      .pic-right, .pic-left  {
        p{
          &:after{
            position: relative;
            display: block;
            top: 0;
          }
        }
      }
    }
  }
}



/* Extra Small Devices, Phones */
@media (max-width: 480px) {

  #voucher {
    .form-voucher {

    }
  }
  #about {
    .header-about {
      text-align: center !important;
    }

    .title {
      font-size: 34px;
      margin-bottom: 20px;
      margin-top: 0;
    }
    .title2 {
      margin-top: 20px;
    }
    .team-card {
      .ticket-board {
        font-size: 30px;
        color: #e8e8e8;
      }

    }
  }
  #homepage {
    .go_away {
      display: none;
    }
  }
  .title {
    font-size: 34px;
    margin-bottom: 10px;
    margin-top: 0;
  }
  .icon-places {
    max-width: 120px;
    svg {
      width: 120px;
      height: 54px;
    }
  }
  header {
    h5 {
      font-size: 26px;
    }
    h6 {
      position: relative;
      font-size: 15px;
      &:after {
        top: 70px;
      }
    }
  }
  #homepage {
    margin-top: 20px !important;
    header {
      h3 {
        font-size: 24px;
        margin-bottom: 20px;
        &:after{
          display: none;
        }
      }
      h1{
        font-size: 30px;
      }

    }

    .highlights{
      margin-top: 20px;
    }

  }
  .tour-box {

    .tour-text {
      padding: 10px;
    }
    .tour-city {
      font-size: 14px;
    }
    h4 {
      font-size: 16px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    h5 {
      margin-top: 10px;
      font-size: 22px;
      span {
        font-size: 14px;
      }
    }
  }
  .form-banner {
    padding: 5px 10px;
    .btn {
      width: 100%;
      margin-bottom: 0;
      margin-top: 1px;
      padding: 10px 8px !important;
      font-size: 14px;
      margin-right: 0;
    }
    .form-control {
      display: block;
      width: 100%;
      height: 24px;
    }
    .form-fields {
      margin-bottom: 0;
      margin-top: 1px;
      padding: 5px;
      background-color: #fff;
      height: auto;
      margin-right: 1px;
      &:nth-child(2) {
        width: -moz-calc(50% - 1px);
        /* WebKit */
        width: -webkit-calc(50% - 1px);
        /* Opera */
        width: -o-calc(50% - 1px);
        /* Standard */
        width: calc(50% - 1px);
        margin-right: 1px;
      }

    }
    .title-input {
      font-size: 17px !important;

    }
    .input-group-addon {
      font-size: 19px;
      top: -25px;

    }
  }
  .banner-home {
    max-height: 500px;
    .form-search {
      position: relative;
      top: -42px;
      left: 50%;
      transform: translate(-50%, -42px);
      h2 {
        font-size: 28px;
        margin-bottom: 25px;
      }
    }
  }
  #tour {
    .wrapper-content {
      .tour-journey {
        h4 {
          font-size: 18px;
        }
        .description-group {
          padding-bottom: 20px;
        }
      }
    }
  }
}

@media (max-width: 440px) {
  .banner-home {
    max-height: 480px;
  }

}

@media (max-width: 425px) {
  .banner-home {
    max-height: 460px;
  }
}

@media (max-width: 400px) {
  .banner-home {
    max-height: 440px;
  }
  #tour{
    .wrapper-top {
      min-width: 100%!important;
      overflow-x: hidden;
      .image-tour {
        position: relative;
        z-index: -1;
        img{
          min-width: 110%;
        }
      }
      .top-info {
        display: block;
        margin-top: -130px;

        .info-tour {
          position: relative;
          float: left;


        }
        .info-tour-cta {
          padding: 0;
          width:100%;
          .tour-price {
            font-size: 24px;
            span {
              font-size: 10px;
            }
          }
          .tour-days {
            font-size: 24px;
          }
          .btn-info {
            font-size: 16px;
          }
        }
        .name-tour {
          height:130px !important;
          .title-tour {

            h1 {
              font-size: 20px;
            }
            span {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

}

@media (max-width: 365px) {
  #tour {
    .info-tour-mob {
      padding: 0;
      ul {
        padding-top: 15px;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 10px;

        li {
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 400;
          padding-left: 20px;
          display: block;
          color: #000;
          &:before {
            content: "";
            height: 1em;
            width: 1em;
            display: block;
            float: left;
            margin-left: -1.5em;
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: 100%;
            background-image: url("../images/pin.png");

          }
        }
      }

    }

  }
  .banner-home {
    max-height: 420px;
  }
  #about {
    .person-reference {
      width: 100%;
    }
  }
  #sustainable {
    img {
      max-width: 260px;
      height: auto;
    }
  }
}

@media (max-width: 320px) {
  .banner-home {
    max-height: 400px;
  }
}




