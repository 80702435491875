#blog {
  .blog-writer {
    margin-top: 40px;
    display: flex;
    align-items: left;
    justify-content: flex-start;
    border-top: solid 1px #e4e4e4;
    padding-top: 20px;
    img {
      margin-right: 20px;
      float: left;
      clear: left;
    }
    p {
      padding-top: 40px;
      font-weight: 900;
    }
  }
  .footer-gray {
    background-color: #efecec;
    color: #000000;
a{
  color: #000000;
  &:hover{
    color: $primary-color;
  }
}
    .pic-left{
      display: flex;
      align-items: left;
      justify-content: flex-start;
      background-color: #e4e4e4;
      img{
        margin-right: 20px;
        float:left;
        clear: left;
      }
      p{
        margin-top: 20px;
        font-weight: 900;
        position: relative;
        &:after{
          font-family: FontAwesome;
          content: "\f060";
          position: absolute;
          top: 30px;
          left:0;
        }
      }
  }
    .pic-right{
      display: flex;
      align-items: right;
      justify-content: flex-end;
      img{
        margin-left: 20px;
        float:right;
        clear: right;
      }
      p{
        margin-top: 20px;
         font-weight: 900;
         position: relative;
         &:after{
           font-family: FontAwesome;
           content: "\f061";
           position: absolute;
           top: 30px;
           right:0;
         }

       }

    }
  }
  .blog-gray {
    background-color: #efecec;
    color: #000000;
    .gray-highlight {
      background-color: $header-color;
    }
    .gray-text {
      padding: 40px;
      background-color: $header-color;
      .gray-title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
      }
    }

    .gray-pic {
      float: right;
      clear: right;
      img {
        float: right;
        clear: right;
      }

    }
  }
  .blog-list {
    .list-title {
      padding-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 0;
      background-color: #efecec;
      margin-bottom: 20px;
      h2 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
        text-align: left;
      }
      .list-btn {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

    }

  }
  .blog-post {
    p {
      font-size: 16px;
      margin-bottom: 20px;
    }
    h1 {
      font-size: 24px;
      margin-bottom: 40px;
    }
    .post-pics {
      margin-bottom: 40px;
      .pic-1 {
        position: relative;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .pic-2 {
        margin-left: 15px;
        position: relative;
      }
      .pic-subtitle {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 5;
        padding: 10px;
        width: 100%;
        height: auto;
        background-color: rgba(0,0,0,0.65);
        color: $header-color;
        font-weight: 500;
      }
    }
  }
  .blog-menu {

    .btn-back{
      padding-bottom: 20px;
      font-size: 18px;
      i{
        font-size: 12px;
        margin-top: 8px;
      }
      a{
        color: $mid-gray-color;
        &:hover{
          color: $primary-color;
        }

      }
    }
    .menu-mobile {
      display: none;
      .form-control {
        display: block;
        width: 100%;
        height: 40px;
        padding: 6px 12px;
        font-size: 18px;
        text-transform: uppercase;
        line-height: 18px;
        text-align: center!important;
        color: $primary-color;
        background-color: #fff;
        background-image: none;
        border: 1px solid $primary-color;
        border-radius: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
      }
    }
    .menu-desktop {
      display: block;
    }

    ul {
      display: flex;
      justify-content: center;
    }
    .nav-tabs > li > a {
      margin-right: 2px;
      line-height: 1.42857143;
      border: 1px solid #efecec;
      border-radius: 0 0 0 0;
      color: $dark-gray-color;
    }
    .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
      background-color: #efecec;
    }

    .nav-tabs {
      border-bottom: none;
    }

  }
  .btn {
    padding: 6px 60px;
    margin-bottom: 0;
    font-size: 16px;
    i{
      font-size: 12px;

    }
  }
}

.blog-item-description {
  h2 {
    font-size: 20px;
    margin-bottom: 5px;
  }

  a {
    color: #337ab7;

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    padding-left: 15px;
    list-style-type: inherit;
  }
}
