#budget{

  .wrapper-top{
    padding-bottom: 150px;
    background-image: url('../images/bg-reference.jpg');
    background-repeat: no-repeat;
    background-position: center bottom;
    .pin-step:nth-child(2){
      margin-top: 100px;
    }
    .pin-step:nth-child(3){
      margin-top: 120px;
    }
    .pin-step:nth-child(4){
      margin-top: 130px;
    }

    .pin-step{
      margin-top: 100px;

      .pin-title{
        padding-top: 10px;
        padding-left: 10px;
        width: 80%;
        font-size: 18px;
        font-weight: 500;
        height: auto;
        text-align: left;
        float: left;

      }
      .pin-svg{
        float: left;
        width: 20%;
        height: auto;
        text-align: right;
        .esconde{
          display: block;
        }
      }
    }


  }
}

.wrapper-content{
  background-color: #f5f5f5;
  padding-top: 40px;
  padding-bottom: 40px;
  ul{

    li {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 400;
      padding-left: 10px;
      display: block;
      color: #666666;
      &:before{
        font-family: FontAwesome;
        content: "\f00c";
        display: inline-block;
        padding-right: 3px;
        vertical-align: middle;
        color: $primary-color;
      }
    }
  }
  .form-budget{

    .input-group-addon {

      padding: 3px 12px;
      font-size: 18px;
      line-height: 1;
      color: $primary-color;
      text-align: center;
      background-color: $header-color;
      border: 1px solid #dbd4d4;
      border-radius: 0;
      border-left:0;
    }


    .form-control {
      line-height: 28px;
      display: block;
      padding: 3px 12px;
      font-size: 16px;
      line-height: 1;
      color: #747474;
      background-color: #fff;
      background-image: none;
      border: 1px solid #dbd4d4;
      border-radius: 0;
      -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
      -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
      -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
      transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;


      &#requestquoteform-phone{
        @media (min-width: 991px){
          width: 35%;
        }
      }

    }
    .btn-budget{
      font-size: 18px;
      font-weight: 500;
      color: $header-color;
      width: 100%;
      padding: 15px;
      background-color: $secundary-color;
      @include transition();
      &:hover {
        @include transition();
        background-color: $primary-color;
      }
    }
  }

  .half-field{
    width: 32%;
    float: left;
    margin-right: 2%;

  }
  .half-field1{
    width: 32%;
    float: left;
    margin-right: 2%;

  }
  .half-field2{

    width: 32%;
    float: left;
    margin-right: 0%;


  }

  .tour-highlight-wrapper-img{
    display: none;
    @media (min-width: 991px) {
      display: block;
    }
  }

  &.background{
    padding: 40px 0 0 0;
    @media (min-width: 991px) {
      padding: 0;
    }
    .row{
      @include flexbox;
      @include align-items(center);
    }
    @media (min-width: 1199px) {
      background: url(../images/background-fast-contacts.jpg) no-repeat center left  /   contain;
    }
  }
}



