#map {
     width: 100%;
    height: 250px;
}
#contactos{
    .end-gray{
        position: relative;
        .gray-bg{
            position: absolute;
            top:0;
            left: 0;
            height: 250px;
            width: 50%;
            z-index: -1;
            background-color:#f7f5f5 ;
        }
    }
    h3 {
        margin-bottom: 20px;
        position: relative;
        &:after {
            position: absolute;
            left: 0;
            bottom: -10px;
            content: "";
            height: 5px;
            width: 25px;
            background-color: #acd226;
        }
    }
    .btn-primary{
        color: $header-color;
        font-size: 16px;
        padding:  10px 30px;

        &:hover {
            color: $header-color;
        }
    }
    a{
        color: $primary-color;
        @include transition();
        &:hover{
            color: $secundary-color;
            @include transition();
        }
    }
    address {
        color: $black-color;
    }
    .form-contacts{
        position: relative;
        .form-title{
            height: 60px;
            position: absolute;
            top: -60px;
            width: 100%;
            z-index: 4;
            background-color: $primary-color;
            color: $header-color;
            text-align: center;
            h2{
                font-size: 18px;
                text-transform: uppercase;
                margin-top: 20px;
            }
        }
        .form-fields{
            padding: 35px;
            border: solid 1px #e5e5e5;
            background-color: $header-color;
            .input-group-addon {
                padding: 6px 12px;
                font-size: 18px;
                line-height: 1;
                color: $primary-color;
                text-align: center;
                background-color: $header-color;
                border: 1px solid #dbd4d4;
                border-radius: 0;
                border-left:0;
            }


            .form-control {
                display: block;
                font-size: 16px;
                line-height: 1;
                color: #747474;
                background-color: #fff;
                background-image: none;
                border: 1px solid #dbd4d4;
                border-radius: 0;
                -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
                box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
                -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
                -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
                transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

            }
            .btn-send{
                font-size: 18px;
                font-weight: 500;
                color: $header-color;
                width: 100%;
                padding: 15px;
                background-color: $secundary-color;
                @include transition();
                &:hover {
                    @include transition();
                    background-color: $primary-color;
                }
            }
        }
    }

    .store-slider-wrapper{
        display: block;
        position: relative;
        width: 100%;
        max-width: 970px;
        margin:0 auto;
        padding: 40px 0;
        .store-slider{
            display: block;
            position: relative;
            width: 100%;
            height: 100%;

            .slick-slide{
                margin: 0 10px;
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .slick-dots{
                bottom:-35px;
                &.slick-active{
                    button{
                        background:$secundary-color;
                    }
                }

                li{
                    button{
                        background: rgba($dark-gray-color,0.7);
                        &:focus,&:active,&:visited{
                            background: rgba($dark-gray-color,0.7);
                        }
                        &:hover{
                            background: rgba($dark-gray-color,1);
                        }
                    }
                }
            }

        }
    }

    .slider-navigation{
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;
        @media (min-width: 991px){
            @include inline-flex;
            @include align-items(center);
        }
        .arrow{
            position: absolute;
            &:first-child{
                left:-60px;
                @media (min-width: 1360px){
                    left:-80px;
                }
            }
            &:last-child{
                right:-60px;
                @media (min-width: 1360px){
                    right:-80px;
                }
            }
        }
    }


}

.fill-mobile {
    float: left;
    width: 100%;
    text-align: center;
    font-weight: bold;
}

.fixed-content {
    position: fixed;
    top: 0;
    width: 20.5%;
    margin-top: 0 !important;
}